import axios from "axios";
import { UserInfo } from '../utils/Enums';
// import apiBase from "./baseService";

let config = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
};

let expires = new Date();
expires.setDate(Date.now() + 1000 * 60 * 60 * 24);

let checkFirst = 0;

const API_ADDRESS = `${process.env.REACT_APP_API_ADDRESS}/admin`;
// const API_PAGE_ZIE = 15;
const api = {
  relogin: () => {
    localStorage.clear();
    window.location.href = `${process.env.PUBLIC_URL}`;
  },

  getUserStudentProfile: () => {
    config.headers[UserInfo.token] = localStorage.getItem(UserInfo.tokenHomework);
    return axios
      .get(`${API_ADDRESS}/userStudent/` + localStorage.getItem(UserInfo.idUserHomework), config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        showError(error);
        if (error.response !== undefined && error.response.status === 401) {
          api.relogin();
        }
        return [];
      });
  },

  getUserParentProfile: () => {
    config.headers[UserInfo.token] = localStorage.getItem(UserInfo.tokenHomework);
    return axios
      .get(`${API_ADDRESS}/userParent/` + localStorage.getItem(UserInfo.idParentHomework), config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        showError(error);
        if (error.response !== undefined && error.response.status === 401) {
          api.relogin();
        }
        return [];
      });
  },

  getUserProfile: () => {
    config.headers[UserInfo.token] = localStorage.getItem(UserInfo.token);
    return axios
      .get(`${API_ADDRESS}/user/` + localStorage.getItem("idUser"), config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        showError(error);
        if (error.response !== undefined && error.response.status === 401) {
          api.relogin();
        }
        return [];
      });
  },

  postLogin: function (name, password) {
    let data = { username: name, password: password };
    return axios.post(`${API_ADDRESS}/authenticate`, data);
  },

  postLoginHomework: function (name, password) {
    let data = { username: name, password: password };
    return axios.post(`${process.env.REACT_APP_API_ADDRESS}/homework/authenticate`, data);
  },

  postToken: function (token) {
    let data = { token: token };
    return axios.post(`${API_ADDRESS}/authenticate`, data);
  },

  postTokenHomework: function (token) {
    let data = { token: token };
    return axios.post(`${process.env.REACT_APP_API_ADDRESS}/homework/authenticate`, data);
  },

  getNewToken: function () {
    let token = localStorage.getItem(UserInfo.token);
    if (token !== undefined && token !== "" && token !== " ") {
      config.headers[UserInfo.token] = token;
      // config.headers["idUser"] = localStorage.getItem("idUser");
      return axios
        .get(`${API_ADDRESS}/user/getNewToken`, config)
        .then((response) => {
          // apiBase.setToken(response.data[UserInfo.token]);
          localStorage.setItem(UserInfo.token, response.data[UserInfo.token]);
          return response.data;
        })
        .catch((error) => {
          if (error.response.status !== undefined && error.response.status === 401) {
            localStorage.removeItem(UserInfo.token);
            //api.relogin();
          }
          return {};
        });
    }
  },

  getNav: function (role) {
    let ret = {
      items: [],
    };

    let token = localStorage.getItem(UserInfo.token);
    config.headers[UserInfo.token] = token;
    config.headers[UserInfo.idUser] = localStorage.getItem(UserInfo.idUser);
    return axios
      .get(`${API_ADDRESS}/getNav/${role}`, config)
      .then((response) => {
        ret = response.data;
        return ret;
      })
      .catch((error) => {
        showError(error);
        if (error.response !== undefined && error.response.status === 401) {
          api.relogin();
        }
        return [];
      });
  }
};

var showError = function (value) {

  if (value.response !== undefined && value.response.status === 401) {
    if (checkFirst === 0 && localStorage.getItem("alertcheck") === true) {
      alert("You don't have PERMISSION to do this action or your SESSION is over!!!");
      localStorage.setItem("alertcheck", false)
      checkFirst++;
    }
  } else {
    if (checkFirst === 0) {
      alert(value);
      checkFirst++;
    }
  }
};
export default api;
