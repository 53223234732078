export const UserInfo = {
    idUser: "idUser",
    type: "type",
    role: "role",
    permission: "permission",
    timezone: "timezone",
    token: "authorization",
    tokenHomework: "authorizationHomework",
    idUserHomework: "idUserHomework",
    idParentHomework: "idParentHomework",
}