// import 'react-app-polyfill/ie9'; // For IE 9-11 support
// import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
// import './polyfill'
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

//if(process.env.REACT_APP_SKIP_CONSOLE===true){
// if(process.env.NODE_ENV === "production"){
// }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
