import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.scss';
import { UserProvider } from './UserContext';
import ModalAlert from './components/ModalAlert';
import api from './service/webservice';

const loading = () => <div className="sp-5 text-center">Loading...</div>;

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));
const DefaultLayoutHomework = React.lazy(() => import('./containers/DefaultLayout/DefaultLayoutHomework'));

const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const LoginHomework = React.lazy(() => import('./views/Pages/Login/LoginHomework'));

class App extends Component {

  constructor(props) {
    super(props);

    this.userLogin = () => {
      this.setState({ isLogin: true })
    }

    this.userLoginHomework = () => {
      this.setState({ isLoginHomework: true })
    }

    this.userLogout = () => {
      localStorage.clear();

      this.setState({ isLogin: false }, () => {
        window.location.href = `${process.env.PUBLIC_URL}/#/admin`;
      })
    }

    this.userLogoutHomework = () => {
      localStorage.clear();

      this.setState({ isLoginHomework: false }, () => {
        window.location.href = `${process.env.PUBLIC_URL}/#`;
      })
    }

    this.onErrorAvatar = () => {
      let { user } = this.state;
      user.avatarUrl = '/avatar/default.jpg';
      this.setState({ user })
    }

    this.setRole = (role) => {
      this.setState({
        role: role
      }, () => {
        api.getNav(role).then(

          data => {
            this.setState({ leftNav: data, isLoadedNav: true })
          }
        )
      })
    }

    this.getRole = () => {
      return this.state.role;
    }

    this.setListRole = (listRole) => {
      this.setState({ listRole: listRole }, () => {
        let role = localStorage.getItem("role");
        if (listRole.includes(role)) {
          this.setState({ role: role, isLoadLastRole: true })
        }
        else {
          localStorage.removeItem("role")
        }
      })
    }

    this.getUser = () => {
      api.getUserProfile().then(data => {
        if (data.message === "OK") {
          this.setState({
            user: data
          })
        }
      })
    }

    this.getUserStudent = () => {
      api.getUserStudentProfile().then(data => {
        if (data.message === "OK") {
          this.setState({
            user: data
          })
        }
      })
    }

    this.getUserParent = () => {
      api.getUserParentProfile().then(data => {
        if (data.message === "OK") {
          this.setState({
            user: data
          })
        }
      })
    }

    this.onToggleModalAlert = () => {
      this.setState({ isShowModalAlert: !this.state.isShowModalAlert })
    }

    this.alert = (input) => {
      this.setState({ msgAlert: input }, () => this.onToggleModalAlert())
    }

    this.state = {
      isLogin: false,
      isLoginHomework: false,
      isLoadedNav: false,
      isLoadLastRole: false,
      logout: this.userLogout,
      logoutHomework: this.userLogoutHomework,
      login: this.userLogin,
      loginHomework: this.userLoginHomework,
      role: '',
      listRole: [],
      setListRole: this.setListRole,
      setRole: this.setRole,
      getRole: this.getRole,
      leftNav: {
        items: [],
        windowsHeight: 0,
        isDesktop: true,
      },
      getUser: this.getUser,
      getUserStudent: this.getUserStudent,
      getUserParent: this.getUserParent,
      user: {},
      onErrorAvatar: this.onErrorAvatar,
      isShowModalAlert: false,
      msgAlert: '',
      alert: this.alert
    }
  }

  refreshAPIKey() {
    api.getNewToken();
  }

  render() {
    return (
      <UserProvider value={this.state}>
        {
          this.state.isShowModalAlert
            ?
            <ModalAlert modal={this.state.isShowModalAlert} toggle={this.onToggleModalAlert} msgDialog={this.state.msgAlert} />
            :
            ""
        }
        <ToastContainer />

        <HashRouter>
          <React.Suspense fallback={loading()} >
            <Switch>
              <Route path="/admin" name="Home" render={props =>
              (
                this.state.isLogin && this.state.isLoadedNav && this.state.isLoadLastRole ? <DefaultLayout {...props} /> : <Login {...props} />
              )
              } />
              <Route exact path="/" name="Homework" render={props =>
              (
                this.state.isLoginHomework ? <DefaultLayoutHomework {...props} /> : <LoginHomework {...props} />
              )
              } />
              <Route path="/homework" name="Homework" render={props =>
              (
                this.state.isLoginHomework ? <DefaultLayoutHomework {...props} /> : <LoginHomework {...props} />
              )
              } />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </UserProvider>
    );
  }
}

export default App;
