import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from '../views/viewMain.module.css';

const ModalAlert = ({ modal, toggle, msgDialog }) => {
  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-dialog modal-dialog-centered">
      <ModalHeader className={`${styles.colorModal}`} toggle={toggle}></ModalHeader>
      <ModalBody className="full_modal-content">
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", verticalAlign: "center", minHeight: "120px", textAlign: "center" }}>
          <span dangerouslySetInnerHTML={{ __html: msgDialog }} />
        </div>
      </ModalBody>
      <ModalFooter className={`${styles.colorModal}`} style={{ justifyContent: "center" }}>
        <Button className={`${styles.btnColorMain}`} onClick={toggle}><i className="fa fa-check"><b> OK</b></i></Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAlert;
